<template>
  <div>
    <v-text-field
      v-model="id"
      label="Invoice ID"
    />
    <invoice :tab="tab" />
  </div>
</template>

<script>
export default {
  components: {
    Invoice: () => import('../account/invoices/invoice')
  },
  data () {
    return {
      id: null,
      tab: {
        documentId: this.id,
        documentType: 'invoices',
        typeName: 'Invoice',
        number: 'Num-00000050000004',
        icon: 'mdi-cash-usd-outline',
        isLoaded: true,
        isNew: false,
        isPristine: true,
        canSave: true,
        canUpdate: true,
        canClose: true,
        showComment: false,
        title: 'Invoice Num-00000050000004'
      }
    }
  },
  watch: {
    id (id) {
      this.tab.documentId = id
    }
  }
}
</script>
